//
// Pagination
// --------------------------------------------------

.pagination {
  --#{$prefix}pagination-font-weight: #{$pagination-font-weight};
  --#{$prefix}pagination-active-box-shadow: #{$pagination-active-box-shadow};

  margin-bottom: 0;
}

.page-link {
  display: flex;
  align-items: center;
  height: 100%;
  @include border-radius(var(--#{$prefix}pagination-border-radius));
  font-weight: var(--#{$prefix}pagination-font-weight);
  > i {
    margin-top: 0.0625rem;
    font-size: 1.425em;
  }
  &:focus {
    box-shadow: none;
  }
}

.page-item {
  margin: 0 $pagination-margin;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &.active .page-link {
    box-shadow: var(--#{$prefix}pagination-active-box-shadow);
  }
}

.pagination-sm {
  --#{$prefix}pagination-border-radius: #{$pagination-border-radius-sm};
  --#{$prefix}pagination-font-size: #{$pagination-font-size-sm};
}

.pagination-lg {
  --#{$prefix}pagination-border-radius: #{$pagination-border-radius-lg};
  --#{$prefix}pagination-font-size: #{$pagination-font-size-lg};
}

// Dark color mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .pagination:not([data-bs-theme='light']) {
      --#{$prefix}pagination-color: #{$pagination-color-dark};
      --#{$prefix}pagination-hover-color: #{$pagination-hover-color-dark};
      --#{$prefix}pagination-hover-bg: #{$pagination-hover-bg-dark};
      --#{$prefix}pagination-focus-bg: #{$pagination-hover-bg-dark};
      --#{$prefix}pagination-disabled-color: #{$pagination-disabled-color-dark};
    }
  }
}
