//
// Social buttons
// --------------------------------------------------

@each $brand, $value in $social-buttons {
  .btn-#{$brand} {
    &:hover,
    &:focus,
    &:active {
      color: $white !important; // stylelint-disable-line declaration-no-important
      background-color: $value !important; // stylelint-disable-line declaration-no-important
      border-color: $value !important; // stylelint-disable-line declaration-no-important
      box-shadow: 0
        0.375rem
        1.125rem -0.375rem
        rgba(
          $value,
          0.8
        ) !important; // stylelint-disable-line declaration-no-important
    }
  }
}

.btn-snapchat {
  &:hover,
  &:focus,
  &:active {
    color: $gray-800 !important; // stylelint-disable-line declaration-no-important
  }
}
