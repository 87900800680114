//
// Reboot
// --------------------------------------------------

// Import Google Fonts (Manrope)
@import url($path-to-fonts); // stylelint-disable-line import-notation

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Sticky footer
html,
body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
.page-wrapper {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
}

// Links
a {
  transition: $link-transition;
  &:focus {
    outline: none;
  }
}

// Background transition for links
/* stylelint-disable selector-no-qualifying-type */
a[class^='bg-']:not(.btn),
a[class*='bg-']:not(.btn) {
  transition: background-color 0.25s ease;
}
/* stylelint-enable selector-no-qualifying-type */

// Images
// Responsive images (ensure images don't scale beyond their parents)
img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
svg {
  max-width: 100%;
}
.dark-mode-img {
  display: none;
}

// Responsive iframes
iframe {
  width: 100%;
}

// Text Selection Color
::selection {
  background: var(--#{$prefix}user-selection-color);
}

b,
strong {
  font-weight: $font-weight-bold;
}

small {
  font-weight: inherit;
}

// Disable button :focus outline
button:focus {
  outline: none;
}

// Images / figures
.img-thumbnail {
  background-color: $thumbnail-bg-dark;
}

// Docs container
.docs-container {
  @include media-breakpoint-up(lg) {
    padding-left: 21rem;
  }
  @include media-breakpoint-up(xxl) {
    padding-right: 20rem;
  }
}
